import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/prism-async';
import dark from 'react-syntax-highlighter/dist/esm/styles/prism/tomorrow';
import 'katex/dist/katex.min.css';

type Props = {
  value?: string;
  className?: string;
};

export default function (props: Props) {
  if (!props.value) return null;

  return (
    <ReactMarkdown
      className={props.className}
      plugins={[remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={{
        p: 'div',
        img: ({ ...props }) => (
          <img
            {...props}
            {...(props.alt ? {} : { alt: 'Problem loading image' })}
            className="proc-text-center proc-text-gray-500 proc-uppercase proc-text-xs"
          />
        ),
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <div className="proc-w-full proc-text-base">
              <SyntaxHighlighter
                style={dark}
                language={match[1]}
                PreTag="div"
                showLineNumbers
                customStyle={{ lineHeight: 1.66 }}
                codeTagProps={{ style: { lineHeight: 1.66 } }}
                {...props}
              >
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            </div>
          ) : (
            <code
              className={className}
              style={{
                backgroundColor: '#E5E7EB',
                color: '#F87171',
                fontSize: '1.1rem',
                fontFamily: 'monospace',
                padding: '5px',
                borderRadius: '1px',
              }}
              {...props}
            >
              {children}
            </code>
          );
        },
      }}
    >
      {props.value}
    </ReactMarkdown>
  );
}
