import { getCookieValue } from '../utils/cookies';

export default async function (url: string, args: { [key: string]: any } = {}) {
  // prevent sending cookies, user headers instead
  if (args && args.credentials === 'header') {
    const maybeAuthCookie: string = getCookieValue('border-patrol-jwt') || '';
    args.headers = args.headers || {};
    args.headers['border-patrol-jwt'] = maybeAuthCookie;
    args.credentials = 'omit';
  }

  return await fetch(url, args);
}
