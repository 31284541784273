import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

import Answer from './Answer';
import MathMd from '../../Renderer/math-md';
import { PALE_SEPARATOR } from '../../../constants/colors';
import { TTAQuestion } from '../../../@types';

interface IProps {
  question: TTAQuestion;
}

export default function (props: IProps) {
  const { question } = props;
  const { text, explanation, type, _id: questionId, ...questionData } = question;

  return (
    <div className="proc-w-full proc-pb-4 proc-border-b proc-border-solid proc-border-gray-200">
      <div className="proc-h4 proc-w-full proc-my-4">
        <MathMd value={text} />
      </div>
      <Answer qType={type} id={questionId} {...questionData} />
      {explanation && (
        <div className="proc-flex proc-items-center proc-mt-2 proc-mb-4 proc-text-gray-500 proc-text-sm">
          <FaInfoCircle color={PALE_SEPARATOR} className="proc-mr-2" size={12} />
          <MathMd value={explanation} />
        </div>
      )}
    </div>
  );
}
