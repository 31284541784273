import React, { useEffect, useRef, useState } from 'react';

import BounceLoader from 'react-spinners/BounceLoader';
import BarLoader from 'react-spinners/BarLoader';

interface IProps {
  bar?: boolean;
  color?: string;
  className?: string;
  size?: string;
  show?: boolean;
}

export default (props: IProps) => {
  const { bar = false, className, color, size, show = false } = props;
  const [isLoading, setIsLoading] = useState(show);
  const timeoutTracker = useRef<any>();

  useEffect(() => {
    // do not show loader for a second
    timeoutTracker.current = setTimeout(() => setIsLoading(true), 1000);

    return () => {
      if (timeoutTracker && timeoutTracker.current) clearTimeout(timeoutTracker.current);
    };
  }, []);

  return (
    <div className={`proc-flex proc-items-center proc-justify-center proc-h-full${className ? ` ${className}` : ''}`}>
      {bar ? (
        <BarLoader loading={isLoading} color={color || '#123abc'} />
      ) : (
        <BounceLoader
          loading={isLoading}
          color={color || '#123abc'}
          size={size === 'tiny' ? 15 : size === 'small' ? 25 : 65}
        />
      )}
    </div>
  );
};
