import React from 'react';

interface IProps {
  className?: string;
}

export default function hr(props: IProps) {
  const { className } = props;

  return <div className={`proc-border-solid proc-border-gray-200 proc-border-b ${className ? className : ''}`} />;
}
