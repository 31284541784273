import React from 'react';

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-javascript';
import '../../styles/prism.css';

interface IProps {
  disabled?: boolean;
  setAnswer?: (answer: string) => void;
  passKey?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  value: string;
  language: string;
}

export default function RenderCodeBox(props: IProps) {
  const { language, setAnswer = () => null, passKey, value = '' } = props;

  if (!language) return null;

  return (
    <Editor
      onKeyUp={passKey as any}
      highlight={(code) => highlight(code, languages[language], language)}
      padding={20}
      placeholder=">"
      preClassName={`language-${language} code-box-display`}
      textareaClassName={`language-${language} code-box-input`}
      {...props}
      value={value}
      onValueChange={setAnswer}
      className="h-full"
    />
  );
}
