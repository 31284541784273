import React, { useEffect, useState } from 'react';

import { TTAQuestion } from '../../@types';
import Spinner from '../../fragments/Spinner';
import HR from '../../fragments/HR';
import Error from '../../fragments/Error';
import Question from './Question';
import { getTAExerciseURL } from '../../constants/app';
import fetch from '../../utils/fetch';

type TExercise = {
  title: string;
  description: string;
  questions: TTAQuestion[];
};

interface IProps {
  id: string;
  API_URL: string;
}

export default function (props: IProps) {
  const [exercise, setExercise] = useState<TExercise>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const { id: exerciseId, API_URL } = props;

  const fetchExercises = async () => {
    if (!exerciseId) return;
    let resp, respJSON;
    try {
      resp = await fetch(getTAExerciseURL(API_URL, exerciseId), { credentials: 'header' });
      respJSON = await resp.json();
    } catch (e) {
      setError('Unable to reach server');
      setLoading(false);
      return;
    }

    if (resp.status !== 200) {
      setError(respJSON.error);
      setLoading(false);
      return;
    }

    setExercise(respJSON);
    setLoading(false);
  };

  useEffect(() => {
    fetchExercises();
  }, [exerciseId]);

  if (loading)
    return (
      <div className="h-screen">
        <Spinner bar />
      </div>
    );
  if (!exerciseId) return <Error preset="broken" text="Incorrect URL - exercise ID is missing." />;
  if (error) return <Error preset="broken" text={error} />;
  if (!exercise) return <Error preset="broken" text="Exercise does not seem to exist" />;
  const { title, description, questions } = exercise;

  return (
    <div className="proc-base proc-max-w-screen-md proc-my-2 proc-mx-auto">
      <div className="proc-flex proc-items-center proc-justify-between proc-my md:proc-my-0">
        <h3 className="proc-w-8/12">{title}</h3>
        <div>
          <h5 className="proc-h5">EXERCISE</h5>
          <h4 className="proc-h4 proc-text-center proc--ml-2">#{exerciseId}</h4>
        </div>
      </div>
      {description && <div className="proc-mb">{description}</div>}
      <HR className="proc-mt-2" />
      {questions.map((q) => (
        <Question key={`e-${exerciseId}-q-${q._id}`} question={q} />
      ))}
    </div>
  );
}
