import React from 'react';

import CodeBox from '../../Renderer/code';
import { IQuestionProps } from './Answer';

type TOption = {
  id: string;
  text: string;
};

type TRenderInput = {
  data: TOption[];
  type: string;
  answer: string;
  id: string;
  language?: string;
};

function RenderInput(props: TRenderInput) {
  const { answer: code = '', language = 'python' } = props;

  return <CodeBox value={code} disabled language={language} />;
}

export default function (props: IQuestionProps) {
  const { id, type, options, code_solution } = props;

  return <RenderInput id={`q-${id}`} type={type} data={options} answer={code_solution} />;
}
