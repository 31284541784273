import React from 'react';

import { getLoginURL, HELP_URL, PRODUCTS_URL } from '../constants/app';

export type IError = {
  emotext?: any;
  emoji?: string;
  text?: any;
  extrablock?: any;
  preset?: string;
  noback?: boolean;
};

const presets: { [key: string]: any } = {
  broken: {
    emotext: 'Something is not right',
    emoji: '(╯°□°)╯︵ ┻━┻',
    text: (
      <p>
        Please let us know if this problem persists via our <a href={HELP_URL}>Help page</a>
      </p>
    ),
  },
  pay2win: {
    emotext: 'Access restricted',
    emoji: 'ヾ(⌐■_■)ノ♪',
    text: (
      <p>
        This content is restricted. Go to our <a href={PRODUCTS_URL}>Programs page</a> to get access.
      </p>
    ),
  },
  nothing: {
    emotext: 'No results found',
    emoji: '（>﹏< )彡',
  },
  login: {
    emoji: '┃(・_┃',
    emotext: 'Login to see things here',
    text: 'Content on this page is based on what you do. Log in to do more!',
    noback: true,
    extrablock: (
      <div className="mt-8 flex justify-center">
        <a className="button" href={getLoginURL(window.location.href)}>
          Login / Register
        </a>
      </div>
    ),
  },
};

export default function (props: IError) {
  const { preset } = props;
  let { emotext, emoji, text, extrablock } = props;

  if (preset) {
    const maybePreset = presets[preset];
    if (maybePreset) {
      if (!emotext && maybePreset.emotext !== undefined) emotext = maybePreset.emotext;
      if (maybePreset.emoji !== undefined) emoji = maybePreset.emoji;
      if (!text && maybePreset.text !== undefined) text = maybePreset.text;
      if (maybePreset.extrablock !== undefined) extrablock = maybePreset.extrablock;
    }
  }

  return (
    <div className="proc-p-2">
      <div className="proc-font-space proc-max-w-screen-md">
        <h4 className="proc-text-right">{emoji}</h4>
        <h5 className="proc-text-brand">{emotext}</h5>
        <p className="proc-font-light proc-text-lg">{text}</p>
        {extrablock}
      </div>
    </div>
  );
}
