export const APPLICATIONS_SITE_URL = 'https://apply.univ.ai';

export const EXERCISE_PATH = '/exercise';
export const EXAM_PATH = '/exam';

export const getLoginURL = (redirectURL?: string) => `${APPLICATIONS_SITE_URL}/login?from=${redirectURL}`;
export const HELP_URL = `${APPLICATIONS_SITE_URL}/help`;
export const PRODUCTS_URL = `${APPLICATIONS_SITE_URL}/products`;

export const getExerciseURL = (API_URL: string, id: string) => `${API_URL}/exercise/${id}`;
export const getExerciseSubmitURL = (API_URL: string, id: string) => `${API_URL}/exercise/submit/${id}`;
export const getTAExerciseURL = (API_URL: string, id: string) => `${API_URL}/ta/exercise/${id}`;
export const getTAUserExerciseURL = (API_URL: string, id: string, userId: number) =>
  `${API_URL}/ta/user/exercise/${id}?userId=${userId}`;
export const getAnswerSubmitURL = (API_URL: string) => `${API_URL}/question/answer`;
export const getCommentSubmitURL = (API_URL: string) => `${API_URL}/ta/comment`;
export const getRunCodeURL = (API_URL: string) => `${API_URL}/question/run-code`;
