import React from 'react';
import { FaCheck, FaInfoCircle, FaMedal, FaCheckDouble } from 'react-icons/fa';

import MathMd from '../../Renderer/math-md';
import { IQuestionProps } from './Answer';

type TOption = {
  _id: string;
  text: string;
  points?: number;
  explanation?: string;
};

type TRenderOptions = {
  options: TOption[];
  id: string;
};

function RenderOptions(props: TRenderOptions) {
  const { options, id } = props;

  return (
    <div className="proc-my-2">
      {options.map((o: TOption) => {
        const isBestAnswer = !!o.points;

        return (
          <div key={o.text} className="mb-4">
            <div className="proc-flex proc-flex-row proc-items-center proc-mt">
              <div className="proc-flex proc-items-center proc-w-8">
                {o.points && o.points !== -1 ? (
                  isBestAnswer ? (
                    <FaCheck className="proc-mr proc-text-green-500" />
                  ) : (
                    <FaCheck className="proc-mr proc-text-green-300" />
                  )
                ) : null}
              </div>
              <label className="proc-flex proc-flex-1">
                <input disabled name={id} type="checkbox" value={o.text} checked={isBestAnswer} />
                <span className="proc-ml-2">
                  <MathMd value={o.text} />
                </span>
              </label>
            </div>
            <div className="proc-mb">
              {o.explanation ? (
                <div className="proc-flex proc-items-center">
                  <FaInfoCircle className="proc-mr-2 proc-text-gray-500" />
                  <MathMd value={o.explanation} />
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default function (props: IQuestionProps) {
  const { id, options } = props;

  return <RenderOptions id={`q-${id}`} options={options} />;
}
