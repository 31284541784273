import React from 'react';

import { IQuestionProps } from './Answer';

type TOption = {
  id: string;
  text: string;
};

type TRenderInput = {
  data: TOption[];
  type: string;
  answer: string;
  id: string;
};

function RenderInput(props: TRenderInput) {
  const { id, answer } = props;

  return <input className="proc-input proc-mt proc-mb-2" disabled name={id} type="text" value={answer} />;
}

export default function (props: IQuestionProps) {
  const { id, type, options, expected_text } = props;

  return <RenderInput id={`q-${id}`} type={type} data={options} answer={expected_text} />;
}
