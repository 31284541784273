import React from 'react';
import Radio from './Radio';
import Check from './Check';
import Free from './Free';
import Code from './Code';

export interface IQuestionProps {
  [key: string]: any;
}

type TProps = IQuestionProps & { qType: string };

export default function (props: TProps) {
  const { qType, ...data } = props;

  if (qType === 'radio') return <Radio {...data} />;
  if (qType === 'mcq') return <Check {...data} />;
  if (qType === 'text') return <Free {...data} />;
  if (qType === 'code') return <Code {...data} />;
  return null;
}
